@import 'styles/bootstrap.min.css';
@import 'styles/bootstrap-theme.min.css';
@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
main {
  min-height: 85vh;
}
@media screen and (min-width: 992px) {
  main {
    min-height: 75vh;
  }
}

@media (max-width: 992px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .hidden-tablet {
    display: none;
  }
}

@media (max-width: 992px) {
  .hidden-max-tablet {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hidden-med-and-up {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .hidden-sm-and-up {
    display: none;
  }
}

@media (max-width: 500px) {
  .hidden-xs {
    display: none;
  }
}

@media (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
}

.hidden-all {
  display: none;
}

.indexscss {
  display: block;
}

* {
  margin: 0px;
  padding: 0px;
}

#root {
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .paddingForPromoBanner {
    padding-top: 80px;
  }
}

@media (max-width: 992px) {
  .mobileTopPadding {
    padding-top: 55;
  }
}
@media (max-width: 500px) {
  .mobileTopPadding {
    /* Different padding for the partnership sites but not bump itself*/
    padding-top: 80px;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #231F20;
  font-family: "Open Sans", Helvetica, sans-serif;
}
@media (max-width: 767px) {
  body {
    line-height: 1.7em;
    font-size: 16px;
  }
  body input.form-control {
    font-size: 16px;
  }
  body select.form-control {
    font-size: 16px;
  }
  body input.InputElement {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  body {
    line-height: 1.9em;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .font-size-body {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .font-size-body {
    font-size: 14px;
  }
}

ol,
ul {
  margin-left: 20px;
}

h1 {
  font-size: 36px;
  line-height: 1.1em;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  line-height: 1.1em;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  line-height: 1.17em;
  font-weight: 700;
}

h4 {
  text-transform: uppercase;
}

h4,
h5,
h3.asH4 {
  font-size: 16px;
  line-height: 1.25em;
  font-weight: 600;
}

h6 {
  font-size: 14px;
  font-weight: bold;
}

.header12 {
  font-size: 0.5em;
}

.header13 {
  font-size: 0.7em;
  line-height: 1;
}

.header14 {
  font-size: 0.8em;
}

.small-line-height {
  line-height: 1em;
}

.medium-line-height {
  line-height: 1.1em;
}

.no-horizontal-padding {
  padding-right: 0;
  padding-left: 0;
}

.dark-gray-text {
  color: #95989A;
}

.primary-text {
  color: #A3DED2;
}

a {
  color: #3C7C70;
  font-weight: 700;
  text-decoration: underline;
}
a:hover {
  color: #95989A;
  cursor: pointer;
}
a:active {
  text-decoration: none;
  color: #95989A;
}
a:focus {
  text-decoration: none;
  color: #95989A;
}

/* For elements that are not anchor tags that act as links */
.link:hover {
  cursor: pointer;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #D6D6D6;
}

.gray-line {
  border-color: #e0e0e0;
}

.border-right {
  border-right: 1px solid #e0e0e0;
}

.spaceAbove80 {
  margin-top: 80px;
}

.btn {
  border-radius: 12.5px;
}

.btn.place-credit-card-order {
  background-color: #38804B;
  color: #FFFFFF;
  background-image: none;
  width: 100%;
  height: 50px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 1.2em;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .btn.place-credit-card-order {
    max-width: 368px;
  }
}
.btn.place-credit-card-order:hover {
  background-color: #38804B;
  color: #FFFFFF;
  opacity: 0.8;
}

.btn-primary {
  background-color: #3C7C70;
  color: #FFFFFF;
  background-image: none;
  width: 100%;
  border: none;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
  padding: 15px 25px;
}
@media screen and (min-width: 992px) {
  .btn-primary {
    max-width: 368px;
  }
}
.btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #3C7C70;
  border-color: #3C7C70;
  opacity: 0.8;
}
.btn-primary[disabled] {
  cursor: default;
  background-color: #F4F4F4;
  opacity: 1;
}
.btn-primary[disabled] a {
  color: #FFFFFF;
}

.btn-incognito {
  background-color: transparent;
  color: #695C26;
  background-image: none;
  max-width: 368px;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9em;
  padding: 0px;
  margin: 0px;
  border: none;
}
.btn-incognito svg {
  margin: 0px;
}

.btn-default {
  background-color: #FFFFFF;
  border: 3px solid #3C7C70;
  color: #000000;
  background-image: none;
  text-transform: uppercase;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9rem;
  text-shadow: none;
  width: 100%;
  padding: 13px 25px;
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .btn-default {
    max-width: 368px;
  }
}
.btn-default:hover, .btn-default:active, .btn-default:not(:disabled):not(.disabled):active {
  background-color: #FFFFFF;
  border: 3px solid #3C7C70;
  color: #000000;
  opacity: 0.8;
}
.btn-default.btn-lg {
  font-size: 1.4em;
}

.btn-info {
  background-color: #FFFFFF;
  border: 3px solid #3C7C70;
  color: #000000;
  background-image: none;
  text-transform: uppercase;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9rem;
  text-shadow: none;
  width: 100%;
  padding: 13px 25px;
  font-weight: bold;
}
@media screen and (min-width: 992px) {
  .btn-info {
    max-width: 368px;
  }
}
.btn-info:hover, .btn-info:active, .btn-info:not(:disabled):not(.disabled):active {
  background-color: #FFFFFF;
  border: 3px solid #3C7C70;
  color: #000000;
  opacity: 0.8;
}
.btn-info.btn-lg {
  font-size: 1.4em;
}

.cta-button {
  width: 100%;
  font-size: 1rem;
}

.btn-link {
  color: #2E6057;
  background-image: none;
  border: none;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9em;
  text-shadow: none;
  padding: 13px 25px;
  letter-spacing: 1px;
  font-weight: bold;
}
.btn-link:hover {
  color: #75BDB0;
}

.btn-faq {
  text-align: left;
  background-color: #F4F4F4;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
}
.btn-faq:focus {
  background-color: #95989A;
  color: #FFFFFF;
}

.infoPopupButton {
  color: #474747;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 5px;
}

/* Automatically add spacing for buttons that contain icons. Such as the lock
   icon on checkout */
button svg {
  margin-right: 8px;
}

.pagination {
  flex-wrap: wrap;
  justify-content: center;
}
.pagination .page-item {
  margin-bottom: 5px;
}
.pagination .page-item .page-link {
  color: #3C7C70;
  border-color: #3C7C70;
  margin-right: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  border-radius: 12.5px;
}
.pagination .page-item .page-link:hover {
  color: #FFFFFF;
  background-color: #3C7C70;
}
.pagination .page-item.active .page-link {
  background-color: #3C7C70;
  color: #FFFFFF;
}

.float-right {
  float: right;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
  margin: 0px auto;
}

.left-align {
  text-align: left;
}

.centered-block {
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.display-block {
  display: block;
}

.form-error {
  color: #3C7C70;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #F4F4F4;
}

.dark-backdrop {
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.paddingXl {
  padding-top: 150px;
}

.mainLinks {
  color: #3C7C70;
  font-size: 1em;
  font-weight: 700;
}
.mainLinks:hover {
  color: #95989A;
  cursor: pointer;
}

.textLink-info {
  color: #231F20;
  font-size: 14px;
  font-weight: 400;
}
.textLink-nav {
  color: #231F20;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
}

.textRight {
  text-align: right;
}

.react-calendar__month-view__days__day--weekend {
  color: #231F20;
}

@media screen and (min-width: 768px) {
  .react-calendar {
    width: 350px;
  }
}
@media (max-width: 767px) {
  .react-calendar {
    width: 275px;
  }
}

.admin-nav-placeholder {
  height: 100px;
}

.creatingSubContainer {
  overflow-x: hidden;
}
@media screen and (min-width: 992px) {
  .creatingSubContainer {
    margin-left: 150px;
  }
}
@media (max-width: 992px) {
  .creatingSubContainer {
    margin: 10px;
  }
}

.inline-text {
  display: inline-block;
}

.wordbreak {
  word-wrap: break-word;
}

.bitsyBrand {
  color: #3C7C70;
}

.text-bitsy-color {
  color: #75BDB0;
}

.textWhite {
  color: white;
}
@media (max-width: 767px) {
  .textWhite {
    display: none;
  }
}

.displayInlineText {
  display: inline-block;
  padding-right: 3px;
  padding-left: 3px;
}

.fullScreen {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .optionalSpaceAbove80 {
    margin-top: 50px;
  }
}
@media screen and (min-width: 768px) {
  .optionalSpaceAbove80 {
    margin-top: 30px;
  }
}

/* The sr-only (screen reader only) styles should have no visual impact,
but WAVE is counting this section as having no contrast. */
.carousel-control-next .sr-only,
.carousel-control-prev .sr-only {
  color: #231F20;
}

.carousel-control-prev-icon {
  /* %23 is the escaped # in the fill color hex code */
  /* fill='%2375BDB0' <-- This is creating a
  color without the literal hash and instead using %23 */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23474747' viewBox='0 0 512 512'%3e%3cpath d='M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM310.6 345.4c12.5 12.5 12.5 32.75 0 45.25s-32.75 12.5-45.25 0l-112-112C147.1 272.4 144 264.2 144 256s3.125-16.38 9.375-22.62l112-112c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L221.3 256L310.6 345.4z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  /* %23 is the escaped # in the fill color hex code */
  /* fill='%2375BDB0' <-- This is creating a
  color without the literal hash and instead using %23 */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23474747' viewBox='0 0 512 512'%3e%3cpath d='M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM358.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L290.8 256L201.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C364.9 239.6 368 247.8 368 256S364.9 272.4 358.6 278.6z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}

/* Move dots under the boxes */
.carousel-indicators {
  bottom: -55px;
  margin-bottom: 35px;
  z-index: 1;
}
.carousel-indicators li {
  border: 1px solid #95989A;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 1;
}
.carousel-indicators .active {
  background-color: #95989A;
}

.modal-dialog {
  position: fixed;
  display: block;
  left: 0;
  right: 0;
}
.modal-dialog .modal-content h1 {
  width: 100%;
}

.form-control {
  background-color: #F4F4F4;
  border: none;
}

.form-check {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

.invalid-feedback {
  color: white;
  background-color: #3C7C70;
  padding-left: 16px;
  font-weight: bold;
  border-radius: 4px;
}

.divider {
  border-bottom: 1px solid #e0e0e0;
}

sup {
  top: -0.3em;
}

.calloutText {
  background-color: #F4F4F4;
  border-left: 4px solid #95989A;
  padding: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: left;
}

.progress {
  border: 1px solid #D6D6D6;
  border-radius: 16px;
}
