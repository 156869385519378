@import '../../styles/styles.scss';

.sms-optIn-container {
  background-color: $primary-200;
  line-height: normal;
  border-radius: 8px;
  padding: 10px;
  margin: 5px;

  .input-group-text {
    box-shadow: none !important;
    background: white;
    border: none;
    padding-right: 1px;
  }

  p {
    margin-bottom: 0;
  }

  a {
    margin: 0px;
    padding: 0px;
    line-height: 12px;
  }
}

#myAccountSMSOptInContainer {
  padding: 15px;
}
