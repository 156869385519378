@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.heronew-container {
  min-height: 450px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .heronew-container {
    background-size: cover;
    min-height: 370px;
  }
}
@media (max-width: 767px) {
  .heronew-container {
    min-height: 180px;
  }
}
.heronew-container-right {
  background-position: top left;
  background-size: contain;
}
@media (max-width: 767px) {
  .heronew-container-right {
    min-height: 400px;
  }
}
.heronew-container-center {
  background-size: cover;
}
.heronew-container.heronew-container-left {
  background-position: top right;
  background-size: contain;
}
@media (max-width: 767px) {
  .heronew-container.heronew-container-left {
    background-size: 115%;
  }
}
.heronew-container-center-top {
  align-items: flex-start;
}
@media (max-width: 992px) {
  .heronew-container-center-top {
    background-size: contain;
  }
}
.heronew-container .heronew-center {
  text-align: center;
}
@media (max-width: 767px) {
  .heronew-container .heronew-center {
    margin-top: 50%;
    margin-bottom: 5%;
  }
}
.heronew-container .heronew-center-top {
  text-align: center;
  margin-top: 60px;
}
@media (max-width: 992px) {
  .heronew-container .heronew-center-top {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 40%;
  }
}
.heronew-container .heronew-right {
  text-align: left;
  max-width: 550px;
  margin-left: 50%;
}
@media (max-width: 992px) {
  .heronew-container .heronew-right {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 50%;
    background-color: white;
    padding: 10px 10px;
  }
  .heronew-container .heronew-right h1 {
    padding: 0 15px;
  }
}
.heronew-container .heronew-left {
  text-align: left;
  max-width: 600px;
  margin-left: 30px;
  color: #000000;
}
@media (max-width: 1440px) {
  .heronew-container .heronew-left {
    max-width: 36%;
  }
}
@media (max-width: 992px) {
  .heronew-container .heronew-left {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-top: 60%;
    margin-top: 65%;
  }
  .heronew-container .heronew-left h1,
  .heronew-container .heronew-left p {
    padding: 10px;
  }
}
.heronew-container .heronew-left.col {
  margin-bottom: 0px;
}
.heronew-container .heronew-left.col h1 {
  font-size: 40px;
  line-height: 35px;
}
.heronew-container p {
  font-size: 16px;
  line-height: 14px;
}
.heronew-container .secondButton {
  width: 100%;
  margin: 15px 0px;
  color: #3C7C70;
  background-color: #FFFFFF;
  font-size: 1rem;
  text-decoration: underline;
  text-shadow: none;
  box-shadow: none;
}
.heronew-container .secondButton:hover, .heronew-container .secondButton:active, .heronew-container .secondButton:not(:disabled):not(.disabled):active {
  color: #3C7C70;
  background-color: #FFFFFF;
  opacity: 0.8;
}
.heronew-container .secondButton.btn-lg {
  font-size: 1.4em;
}
.heronew-container #starsHappyMothers {
  font-size: 12px;
}
@media (max-width: 992px) {
  .heronew-container #starsHappyMothers {
    text-align: center;
  }
}
